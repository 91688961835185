import { Spin } from 'antd';
import { Suspense } from 'react';
import { AuthenticationWrap } from './overview/style';
import Heading from '../../../components/heading/heading';
import './style.css';
import videoBg from '../../../static/video/turkey-world.mp4';

const AuthLayout = (WraperContent) => {
  return function () {
    return (
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <AuthenticationWrap>
          <div className="video-background">
            <div className="overlay"> </div>
            <video src={videoBg} autoPlay loop muted />
          </div>
          <div className="ninjadash-authentication-wrap">
            <div className="ninjadash-authentication-brand">
              <div
                style={{
                  display: 'flex',
                  width: 'auto',
                  height: '105px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  style={{
                    width: 'auto',
                    height: '105px',
                    marginRight: '10px',
                  }}
                  src={require(`../../../static/img/logo/csbmpn.png`)}
                  alt=""
                />
                <img
                  style={{
                    width: 'auto',
                    height: '75px',
                  }}
                  src={require(`../../../static/img/logo/yerbisn.png`)}
                  alt=""
                />
              </div>
              <figcaption style={{ marginTop: '25px' }}>
                <Heading className="" as="h2">
                  <span style={{ color: '#fff' }}>YER BİLİMSEL ETÜT BİLGİ SİSTEMİ</span>
                </Heading>
                {/* <span className="user-designation">San Francisco, CA</span> */}
              </figcaption>
            </div>
            <div>
              <WraperContent />
            </div>
          </div>
        </AuthenticationWrap>
      </Suspense>
    );
  };
};

export default AuthLayout;
