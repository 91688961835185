import Cookies from 'js-cookie';
import actions from './actions';

const {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_ERR,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  LOGOUT_ERR,
  LOGIN_PASSWORD_TIME,
  LOGIN_PASSWORD_TIME_RES,
} = actions;

const initState = {
  login: Cookies.get('logedIn'),
  loading: false,
  error: null,
  user: JSON.parse(localStorage.getItem('user')) || null,
  passwordData: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, err, payload } = action;
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: payload.isLoggedIn,
        user: payload.userData,
        loading: false,
      };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: payload.isLoggedIn,
        user: payload.userData,
        loading: false,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGIN_PASSWORD_TIME:
      return {
        ...state,
        passwordData: payload.dataPassword,
      };
    case LOGIN_PASSWORD_TIME_RES:
      return {
        ...state,
        passwordData: payload.dataPassword,
      };
    default:
      return state;
  }
};

export const selectUser = (state) => state.auth.user;

export const isAuthenticated = (state) => state.auth.user !== null && state.auth.login;

export const hasRole = (role) => (state) => {
  return (
    state.auth.user !== null &&
    state.auth.login &&
    state.auth.user.userGroups.some((group) => group.userGroupId === role)
  );
};

export const hasRoles = (roles) => (state) => {
  return (
    state.auth.user !== null &&
    state.auth.login &&
    Array.isArray(state.auth.user.userGroups) &&
    roles.some((role) => state.auth.user.userGroups.some((group) => group.userGroupId === role))
  );
};

export const hasType = (type) => (state) => {
  return state.auth.user.type === type;
};

export const hasTypes = (types) => (state) => {
  return types.includes(state.auth.user.type);
};

export default AuthReducer;
