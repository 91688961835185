import actions from './actions';
import { DataService } from '../../config/dataService/dataService_yerbis';

const { MenuReadSuccess, MenuReadBegin } = actions;

const MenuDataRead = () => {
  return async (dispatch) => {
    try {
      const query = await DataService.post(`${process.env.REACT_APP_PROJE_MENU_LIST_ENDPOINT}`);
      if (query.data?.isSuccess) {
        dispatch(MenuReadSuccess(query.data?.model));
      }
    } catch (err) {
      //   await dispatch(ReadErr(err));
    }
  };
};

const MenuRead = () => {
  return async (dispatch) => {
    try {
      dispatch(MenuReadBegin());
      localStorage.setItem('reduxMenuItemUpdate', JSON.stringify(Date.now()));
    } catch (err) {
      //   await dispatch(ReadErr(err));
    }
  };
};

export { MenuDataRead, MenuRead };
