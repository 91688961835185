import Styled from 'styled-components';

const SpinerCustomStyle = Styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: rgb(167 167 167 / 17%);
  border-radius: 10px;
`;

const BilgiDetayDiv = Styled.div`
figure{
    position: relative;
    max-width: 120px;
    .ant-upload-select{
        position: absolute;
        ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 0;
        bottom: -2px;
        height: 40px;
        width: 40px;
        background: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        span{
            display: inline-flex;
            height: 32px;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            width: 32px;
            background: ${({ theme }) => theme['primary-color']};
        } 
        a{
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            svg{
                width: 16px;
                height: 16px;
            }
        }
    }
}
figcaption{
    .info{
        h1,
        h2,
        h3,
        h4,
        h5,
        h6{
            font-size: 18px;
            margin-bottom: 4px;
        }
        p{
            margin-bottom: 0;
            color: ${({ theme }) => theme[theme.mainContent]['gray-light-text']};
        }
    }
}
`;

const BilgiDiv = Styled.div`
    .ant-card-body{
        padding: 25px 0 25px !important;
    }
    .author-info{
        padding: 0 20px 20px;
        text-align: center;
        border-bottom: 1px solid ${({ theme }) => theme[theme.mainContent]['border-color-default']};
        .info{
            background-color: transparent;
        }
    }
    figure{
        position: relative;
        max-width: 120px;
        margin: 0 auto 18px;
        .ant-upload-select{
            position: absolute;
            ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 0;
            bottom: -2px;
            height: 40px;
            width: 40px;
            background: #fff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            span{
                display: inline-flex;
                height: 32px;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 32px;
                background: ${({ theme }) => theme['primary-color']};
            } 
            a{
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                svg{
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
    figcaption{
        .info{
            h1,
            h2,
            h3,
            h4,
            h5,
            h6{
                font-size: 18px;
                margin-bottom: 4px;
            }
            p{
                margin-bottom: 0;
                color: ${({ theme }) => theme[theme.mainContent]['gray-light-text']};
            }
        }
    }

    .settings-menmulist{
        padding: 20px 20px 0px 20px;
        li{
            a{
                display: flex;
                align-items: center;
                padding: 12px 20px;
                border-radius: 6px;
                color: ${({ theme }) => theme[theme.mainContent]['gray-light-text']};
                i,
                svg,
                img{
                    width: 16px;
                    height: 16px;
                    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 13px;
                }
                &.active{
                    font-weight: 500;
                    color: ${({ theme }) => theme[theme.mainContent]['menu-active']};
                    background: ${({ theme }) => theme[theme.mainContent]['primary-transparent']};;
                }
            }
        }
    }
`;

const FigureSquare = Styled.figure`
    
    width: 120px;
    height: 120px;
    max-height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #8231d3;
    display:inline-block;

    img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: fill;
    }
    
    .ant-upload-select {
        bottom: -1px !important;
        background: none !important;
        
        svg {
            width: 28px !important;
            height: 28px !important;
        }

    }

    .upload-wrapper{
        display: inline-block;
    }
    
    .ant-upload-list {
        position: absolute;
        left: 0;
        bottom: -32px;
    }
`;

const DeletePhoto = Styled.div`
    margin-left: 8px;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;

    svg{
        color: red !important;
    }
`;

export { BilgiDiv, BilgiDetayDiv, SpinerCustomStyle, DeletePhoto, FigureSquare };
