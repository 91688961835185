import PropTypes from 'prop-types';
import { useAuth } from '../../hooks/useAuth';
import YerbisConstants from '../../constants/yerbisConstants';
import {
  KAMU_MUHENDISI,
  UYGULAMA_YONETICISI,
  JEOFIZIK_MUHENDISI,
  JEOLOJI_MUHENDISI,
  JEOLOJI_VE_JEOFIZIK_MUHENDISI,
} from '../../constants/roleConstant';

function Can({ perform, children }) {
  const { hasRoles } = useAuth();

  if (!hasRoles(perform)) {
    return null;
  }

  return children;
}

function RolCalismaYetki({ calismaTurId, children }) {
  const { hasRoles, hasRole } = useAuth();

  if (hasRoles([UYGULAMA_YONETICISI, KAMU_MUHENDISI])) {
    return children;
  }

  const jeofizikCalismasi = Object.values(YerbisConstants.JeofizikCalismaTurleri).includes(Number(calismaTurId));
  const jeolojiCalismasi = !jeofizikCalismasi;

  if (jeofizikCalismasi && !hasRole(JEOFIZIK_MUHENDISI) && !hasRole(JEOLOJI_VE_JEOFIZIK_MUHENDISI)) {
    return null;
  }

  if (jeolojiCalismasi && !hasRole(JEOLOJI_MUHENDISI) && !hasRole(JEOLOJI_VE_JEOFIZIK_MUHENDISI)) {
    return null;
  }
  return children;
}

function TypeCan({ types, children }) {
  const { hasTypes } = useAuth();

  if (!hasTypes(types)) {
    return null;
  }

  return children;
}

Can.propTypes = {
  perform: PropTypes.arrayOf(PropTypes.number).isRequired,
  children: PropTypes.node.isRequired,
};

RolCalismaYetki.propTypes = {
  calismaTurId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

TypeCan.propTypes = {
  types: PropTypes.arrayOf(PropTypes.number).isRequired,
  children: PropTypes.node.isRequired,
};

export default Can;
export { TypeCan, RolCalismaYetki };
