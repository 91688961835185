import actions from './actions';

const { MENU_READ_SUCCESS, MENU_READ_BEGIN } = actions;

const initialStateFilter = {
  menuData: [],
  lastUpdated: Date.now(),
};

const Reducer = (state = initialStateFilter, action) => {
  const { type, menuData } = action;
  switch (type) {
    case MENU_READ_SUCCESS:
      return {
        ...state,
        menuData,
      };

    case MENU_READ_BEGIN:
      return {
        ...state,
        lastUpdated: Date.now(),
      };

    default:
      return state;
  }
};

export { Reducer };
