const actions = {
  MENU_READ_SUCCESS: 'MENU_READ_SUCCESS',
  MENU_READ_BEGIN: 'MENU_READ_BEGIN',

  MenuReadSuccess: (menuData) => {
    return {
      type: actions.MENU_READ_SUCCESS,
      menuData,
    };
  },

  MenuReadBegin: () => {
    return {
      type: actions.MENU_READ_BEGIN,
    };
  },
};

export default actions;
