import React, { useEffect, useState, useContext } from 'react';
import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import UisKeySkeleton from '@iconscout/react-unicons/icons/uil-key-skeleton';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import { Avatar } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';
import { useAuth } from '../../../hooks/useAuth';

import { KURUM, FIRMA, MUHENDIS, ADMIN as ADMINTYPE } from '../../../constants/typeConstant';
import {
  JEOLOJI_VE_JEOFIZIK_MUHENDISI,
  JEOFIZIK_MUHENDISI,
  JEOLOJI_MUHENDISI,
  SUBE_MUDURU,
  BAKANLIK_DAIRE_BASKANI,
  GENEL_MUDUR_YARDIMCISI,
  GENEL_MUDUR,
  IL_MUDUR_YARDIMCISI,
  IL_MUDURU,
  IL_SUBE_MUDURU,
  KAMU_MUHENDISI,
  ADMIN,
  KOMISYON,
  KOMISYON_UYESI,
  KOMISYON_YEDEK_UYESI,
  UYGULAMA_YONETICISI,
  DUYURU_YETKILISI,
  FIRMA_ONAY_YETKILI,
  PROJE_ALANI_KILITLEME_YETKILI,
  PROJE_DURUMU_DEGISTIRME,
  KURUM_KULLANICI_DUZENLEME_YETKILI,
  ILLER_BANKASI,
  ILBANK,
  IL_MUHENDISI,
  MUHENDIS as MUHENDISROLE,
} from '../../../constants/roleConstant';
import { DataService } from '../../../config/dataService/dataService_yerbis';
import NotificationHelper from '../../../utility/notificationHelper';
import YerbisConstants from '../../../constants/yerbisConstants';
import { t } from 'i18next';

const AuthInfo = React.memo(() => {
  const { user, logout } = useAuth();
  const [muhendisData, setMuhendisData] = useState({});
  const [firmaData, setFirmaData] = useState({});
  const [kurumData, setKurumData] = useState({});
  const baseUrl = `${process.env.REACT_APP_YERBIS_TEST_APP_API_ENDPOINT}`;

  const getMuhendisData = async () => {
    try {
      const response = await DataService.post(`${process.env.REACT_APP_MUHENDIS_GET_ENDPOINT}`, {
        muhendisId: user.muhendisIdHash,
      });
      setMuhendisData({ ...response.data.model[0] });
    } catch (err) {
      NotificationHelper.setErrorNotification();
    } finally {
      //
    }
  };

  const getFirmaData = async () => {
    try {
      const response = await DataService.post('/Api/List/FirmaGetByid', {
        firmaId: user.userNameHashStr,
      });

      setFirmaData({ ...response.data.model[0] });
    } catch (err) {
      NotificationHelper.setErrorNotification();
    } finally {
      //
    }
  };

  const getKurumData = async () => {
    try {
      const response = await DataService.post('/Api/List/KurumGetByid', {
        firmaId: user.userNameHashStr,
      });
      console.log(response);

      setKurumData({ ...response.data.model[0] });
    } catch (err) {
      NotificationHelper.setErrorNotification();
    } finally {
      //
    }
  };

  useEffect(() => {
    switch (user.type) {
      case KURUM:
        getKurumData();
        break;
      case FIRMA:
        getFirmaData();
        break;
      case MUHENDIS:
        getMuhendisData();
        break;
      case ADMINTYPE:
        break;
      default:
        break;
    }
  }, []);

  let profileUrl = '';
  switch (user.type) {
    case KURUM:
      profileUrl = `/admin/BilgiSayfalari/KurumBilgileri/KurumDetay/${user.userNameHashStr}`;
      break;
    case FIRMA:
      profileUrl = `/admin/BilgiSayfalari/FirmaBilgileri/FirmaDetay/${user.userNameHashStr}`;
      break;
    case MUHENDIS:
      profileUrl = `/admin/BilgiSayfalari/MuhendisBilgileri/MuhendisDetay/${user.muhendisIdHash}`;
      break;
    case ADMINTYPE:
      profileUrl = `/admin/BilgiSayfalari/MuhendisBilgileri/MuhendisDetay/${user.muhendisIdHash}`;
      break;
    default:
      break;
  }

  let changePasswordUrl = '';
  switch (user.type) {
    case KURUM:
      changePasswordUrl = `/admin/BilgiSayfalari/KurumBilgileri/SifreDegistir/${user.userNameHashStr}`;
      break;
    case FIRMA:
      changePasswordUrl = `/admin/BilgiSayfalari/FirmaBilgileri/SifreDegistir/${user.userNameHashStr}`;
      break;
    case MUHENDIS:
      changePasswordUrl = `/admin/BilgiSayfalari/MuhendisBilgileri/SifreDegistir/${user.muhendisIdHash}`;
      break;
    case ADMINTYPE:
      changePasswordUrl = `/admin/BilgiSayfalari/MuhendisBilgileri/SifreDegistir/${user.muhendisIdHash}`;
      break;
    default:
      break;
  }

  const roles = {
    [GENEL_MUDUR]: 1,
    [GENEL_MUDUR_YARDIMCISI]: 2,
    [BAKANLIK_DAIRE_BASKANI]: 3,
    [IL_MUDURU]: 4,
    [IL_MUDUR_YARDIMCISI]: 5,
    [SUBE_MUDURU]: 6,
    [JEOLOJI_MUHENDISI]: 7,
    [JEOFIZIK_MUHENDISI]: 8,
    [JEOLOJI_VE_JEOFIZIK_MUHENDISI]: 9,
  };

  const rolePriority = {
    [GENEL_MUDUR]: 1,
    [GENEL_MUDUR_YARDIMCISI]: 2,
    [BAKANLIK_DAIRE_BASKANI]: 3,
    [IL_MUDURU]: 4,
    [IL_MUDUR_YARDIMCISI]: 5,
    [SUBE_MUDURU]: 6,
    [JEOLOJI_MUHENDISI]: 7,
    [JEOFIZIK_MUHENDISI]: 8,
    [JEOLOJI_VE_JEOFIZIK_MUHENDISI]: 9,
    [IL_SUBE_MUDURU]: 10,
    [KAMU_MUHENDISI]: 11,
    [FIRMA]: 12,
    [ADMIN]: 13,
    [KOMISYON]: 14,
    [KOMISYON_UYESI]: 15,
    [KOMISYON_YEDEK_UYESI]: 16,
    [UYGULAMA_YONETICISI]: 17,
    [DUYURU_YETKILISI]: 18,
    [FIRMA_ONAY_YETKILI]: 19,
    [PROJE_ALANI_KILITLEME_YETKILI]: 20,
    [KURUM_KULLANICI_DUZENLEME_YETKILI]: 21,
    [PROJE_DURUMU_DEGISTIRME]: 22,
    [ILLER_BANKASI]: 23,
    [ILBANK]: 24,
    [IL_MUHENDISI]: 25,
    [MUHENDISROLE]: 26,
  };

  let highestRole = '';

  user.userGroups.forEach((g) => {
    const groupId = g.userGroupId;

    if (roles[groupId] && (!highestRole || rolePriority[groupId] < rolePriority[highestRole])) {
      highestRole = groupId;
    }
  });

  if (!highestRole) {
    highestRole = t('diger'); // Diğer olarak belirliyoruz
  }

  switch (highestRole) {
    case GENEL_MUDUR:
      highestRole = t('genelMudur');
      break;
    case GENEL_MUDUR_YARDIMCISI:
      highestRole = t('genelMudurYrd');
      break;
    case BAKANLIK_DAIRE_BASKANI:
      highestRole = t('daireBaskani');
      break;
    case IL_MUDURU:
      highestRole = t('ilMudur');
      break;
    case IL_MUDUR_YARDIMCISI:
      highestRole = t('ilMudurYrd');
      break;
    case SUBE_MUDURU:
      highestRole = t('subeMuduru');
      break;
    case JEOLOJI_VE_JEOFIZIK_MUHENDISI:
      highestRole = t('jeolojivejeofizikMuh');
      break;
    case JEOLOJI_MUHENDISI:
      highestRole = t('jeolojiMuh');
      break;
    case JEOFIZIK_MUHENDISI:
      highestRole = t('jeofizikMuh');
      break;
    default:
      break;
  }

  console.log(user);

  let profileUserName = '';
  switch (user.type) {
    case KURUM:
      profileUserName = user.name ? user.name : kurumData.FirmaAdi ?? '';
      break;
    case FIRMA:
      profileUserName = firmaData.FirmaAdi ?? '';
      break;
    case MUHENDIS:
      profileUserName = `${muhendisData.Ad ?? ''} ${muhendisData.Soyad ?? ''} - ${highestRole ?? ''}`;
      break;
    case ADMINTYPE:
      profileUserName = `${user.name ?? ''} ${user.surname ?? ''}`;
      break;
    default:
      profileUserName = `${user.name ?? ''} ${user.surname ?? ''}`;
      break;
  }

  const [state, setState] = useState({
    flag: 'tr',
  });
  const navigate = useNavigate();
  const { flag } = state;

  const SignOut = (e) => {
    e.preventDefault();
    logout(() => navigate('/'));
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info align-items-center">
          <img
            style={{
              width: '40px',
              height: '40px',
              objectFit: 'contain',
            }}
            src={`${baseUrl}${firmaData.Logo}`}
            alt="Profil Fotoğrafı"
          />
          <figcaption>
            {user.type === 2 ? (
              <Heading as="h5">
                {muhendisData?.Ad} {muhendisData?.Soyad}
              </Heading>
            ) : user.type === 1 ? (
              <Heading as="h5">{firmaData?.FirmaAdi}</Heading>
            ) : user.type === 0 ? (
              <Heading as="h5">{user.name ? user.name : kurumData?.FirmaAdi}</Heading>
            ) : (
              <Heading as="h5">
                {user.name} {user.surname}
              </Heading>
            )}
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to={profileUrl}>
              <UilUser /> {t('info.profile')}
            </Link>
          </li>
          <li>
            <Link to={changePasswordUrl}>
              <UisKeySkeleton /> {t('info.changePassword')}
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <UilSignout /> {t('info.signOut')}
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = (value, e) => {
    e.preventDefault();
    setState({
      ...state,
      flag: value,
    });
    i18n.changeLanguage(value);
  };

  const country = (
    <NavAuth>
      <Link onClick={(e) => onFlagChangeHandle('tr', e)} to="#">
        <img src={require('../../../static/img/flag/tr.png')} alt="" />
        <span>Türkçe</span>
      </Link>
      <Link onClick={(e) => onFlagChangeHandle('en', e)} to="#">
        <img src={require('../../../static/img/flag/en.png')} alt="" />
        <span>English</span>
      </Link>
    </NavAuth>
  );

  return (
    <InfoWraper>
      {/* <Search />
      <Message />
      <Notification />
      <Settings /> */}
      {/* <Can perform={[KAMU_MUHENDISI]}>
        <div className="ninjadash-nav-actions__item ninjadash-nav-actions__language">
          <Dropdown placement="bottomRight" content={country} trigger="click">
            <Link to="#" className="ninjadash-nav-action-link">
              <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
            </Link>
          </Dropdown>
        </div>
      </Can> */}
      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <Avatar style={{ backgroundColor: 'aliceblue' }} icon={<UilUser />} src={`${baseUrl}${firmaData.Logo}`} />
            <span className="ninjadash-nav-actions__author--name">{profileUserName}</span>
            <UilAngleDown />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
});

export default AuthInfo;
