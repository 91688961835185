import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService_yerbis';
import formHelper from '../../utility/formHelper';

const {
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
  loginPasswordTime,
  loginPasswordTimeRes,
} = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      console.log(values);
      const temp = await formHelper.formSubmit(
        '/Authorazation/Login',
        {
          username: values.username,
          password: values.password,
          bakanlikKullanicisiMi: values.bakanlikKullanicisiMi,
        },
        'post',
        true,
      );
      const response = {
        data: temp.responseData,
      };
      // const response = await DataService.post('/Authorazation/Login', {
      //   username: values.username,
      //   password: values.password,
      // });
      if (!response.data.isSuccess) {
        dispatch(loginPasswordTime(response.data.model));
        dispatch(loginErr(response.data.errorMessages));
      } else {
        Cookies.set('access_token', response.data.model.token);
        Cookies.set('logedIn', true);
        localStorage.clear();
        localStorage.setItem('user', JSON.stringify(response.data.model));

        dispatch(loginSuccess(true, response.data.model));
        if (callback && typeof callback === 'function') {
          callback();
        }
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const loginPasswordTimeRest = () => {
  return async (dispatch) => {
    dispatch(loginPasswordTimeRes());
  };
};

const fbLogin = (callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      Cookies.set('logedIn', true);
      dispatch(loginSuccess(true));
      callback();
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const register = (values) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/register', values);
      if (response.data.errors) {
        dispatch(loginErr('Registration failed!'));
      } else {
        dispatch(loginSuccess(false, null));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      await DataService.post('/Authorazation/Logout');
      Cookies.remove('logedIn');
      Cookies.remove('access_token');
      localStorage.clear();
      dispatch(logoutSuccess(false, null));
      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, register, fbLogin, loginPasswordTimeRest };
