import { notification } from 'antd';
import i18n from 'i18next';
import { alertModal } from '../components/modals/antd-modals';
import { duration } from 'moment';

class NotificationHelper {
  t = useTranslation();

  static showConfirmDialog(title, content, okText = 'Tamam', cancelText = 'İptal') {
    return new Promise((resolve) => {
      alertModal.confirm({
        title,
        content,
        okText,
        cancelText,
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
  }

  static showErrorDialogOk(title, content, okText = 'Tamam', okFunction) {
    return new Promise((resolve) => {
      alertModal.confirm({
        title,
        content,
        okText,
        onOk: () => {
          okFunction();
        },
      });
    });
  }

  // static responseErrorToErrorDialog = () => {
  //   return NotificationHelper.showErrorDialog(
  //     'Proje Oluşturalamıyor',
  //     <div>
  //       {response.data?.errorMessages.map((msg, index) => (
  //         <p key={index}>{msg}</p>
  //       ))}
  //     </div>,
  //   );
  // };

  static showErrorDialog(title, content, okText = 'Tamam') {
    alertModal.error({
      okText,
      title,
      content,
    });
  }

  static setResponseNotifications(response) {
    if (response.data.isSuccess) {
      response.data.successMessages?.forEach((msg) => {
        this.setSuccessNotification(msg);
      });
    } else {
      response.data.errorMessages?.forEach((msg) => {
        this.setErrorNotification(msg);
      });
    }
    response.data.warningMessages?.forEach((msg) => {
      this.setWarningNotification(msg);
    });
  }

  static setSuccessNotification(msg) {
    notification.success({
      message: msg ?? i18n.t('operationIsSuccessful'),
    });
  }

  static setErrorNotification(msg) {
    notification.error({
      message: i18n.t('error'),
      description: msg ?? i18n.t('processUnexpectedError'),
    });
  }

  static setWarningNotification(msg) {
    notification.warning({
      message: i18n.t('warning'),
      description: msg,
    });
  }
}

export default NotificationHelper;
