import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// Ortak global context
export const GlobalContext = createContext();

function GlobalProvider({ children }) {
  const [sharedState, setSharedState] = useState(false);
  const [hendekModalData, setHendekModalData] = useState(null);
  const [projeDetayBilgi, setProjeDetayBilgi] = useState(null);
  const [havaleStateList, setHavaleStateList] = useState(null);

  return (
    <GlobalContext.Provider
      value={useMemo(
        () => ({
          sharedState,
          setSharedState,
          hendekModalData,
          setHendekModalData,
          projeDetayBilgi,
          setProjeDetayBilgi,
          havaleStateList,
          setHavaleStateList,
        }),
        [sharedState, hendekModalData, projeDetayBilgi, havaleStateList],
      )}
    >
      {children}
    </GlobalContext.Provider>
  );
}

// Kullanım için custom hook
GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalProvider;
