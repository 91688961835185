import { notification } from 'antd';
import { DataService } from '../config/dataService/dataService_yerbis';
import NotificationHelper from './notificationHelper';
import moment from 'moment';
// import { convertNumberToRequestData } from './typeHelper.ts';

class formHelper {
  static getFormValues(form) {
    const values = form.getFieldsValue();
    const trimmedValues = {};
    Object.entries(values).forEach(([key, value]) => {
      if (value === undefined || value === null) {
        trimmedValues[key] = '';
      } else {
        trimmedValues[key] = String(value).trim();
      }
    });
    return trimmedValues;
  }

  static isEmptyFieldVal(obj, message = true) {
    const status = Object.values(obj).some(
      (değer) => değer === null || değer === undefined || değer === '' || değer === ' ',
    );
    if (status && message) {
      notification.error({
        message: 'Hata',
        description: 'Boş alanları doldurun.',
      });
    }
    return status;
  }

  static removeKeys(formValue, keys) {
    const updatedFormValue = { ...formValue };
    keys.forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(updatedFormValue, key)) {
        delete updatedFormValue[key];
      }
    });
    return updatedFormValue;
  }

  /* form: json , keys: [] */
  static applyNumberRequestFormat(form, keys) {
    keys.forEach((key) => {
      const value = form[key];
      if (value && !Number.isNaN(Number(value)) && value.includes('.')) {
        form[key] = value.replaceAll('.', ',');
      }
    });
  }

  /* form: json , keys: [], format : "" */
  static applyDateRequestFormat(form, keys, format) {
    keys.forEach((key) => {
      const value = form[key];
      if (value) {
        const formattedValue = moment(value).format(format);
        form[key] = formattedValue;
      }
    });
  }

  static async formSubmit(path, formValue, type = 'post', returnResponseData = false) {
    let status = false;
    let response;
    if (type === 'post') {
      response = await DataService.post(path, formValue);
    } else if (type === 'get') {
      response = await DataService.get(path, formValue);
    } else if (type === 'put') {
      response = await DataService.put(path, formValue);
    } else if (type === 'del') {
      response = await DataService.delete(path, formValue);
    }
    if (response.data.isSuccess) {
      status = true;
    }
    NotificationHelper.setResponseNotifications(response);
    if (returnResponseData) {
      return { responseStatus: status, responseData: response.data };
    }
    return status;
  }

  static cleanEmptyFormValue(obj) {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (value) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }
}

export default formHelper;
