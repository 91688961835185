import { useLayoutEffect, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { DataService } from '../config/dataService/dataService_yerbis';
import { SpinerCustomStyle } from '../container/bilgiSayfalari/style';
import { Spin } from 'antd';
import { MenuDataRead } from '../redux/menuCalismaSayilari/actionCreator';

import { UilBagAlt, UilFile, UilApps, UilLayerGroup, UilMap } from '@iconscout/react-unicons';
import { TopMenuStyle } from './Style';
import Can from '../components/can/can';
import {
  MUHENDIS,
  FIRMA,
  SUBE_MUDURU,
  KAMU_MUHENDISI,
  KOMISYON_UYESI,
  KOMISYON_YEDEK_UYESI,
  UYGULAMA_YONETICISI,
  BAKANLIK_DAIRE_BASKANI,
  GENEL_MUDUR,
  GENEL_MUDUR_YARDIMCISI,
  IL_MUDURU,
  IL_MUDUR_YARDIMCISI,
  ADMIN,
} from '../constants/roleConstant';
import YerbisConstants from '../constants/yerbisConstants';
import NotificationHelper from '../utility/notificationHelper';

function TopMenu() {
  const dispatch = useDispatch();
  const path = '/admin';
  const { t } = useTranslation();
  const { user, hasRole, hasRoles } = useAuth();

  useLayoutEffect(() => {
    dispatch(MenuDataRead());
    const active = document.querySelector('.ninjadash-top-menu a.active');
    const activeDefault = () => {
      const megaMenu = active.closest('.megaMenu-wrapper');
      const hasSubMenuLeft = active.closest('.has-subMenu-left');
      if (!megaMenu) {
        active.closest('ul').previousSibling.classList.add('active');
        if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
      } else {
        active.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
      }
    };
    window.addEventListener('load', active && activeDefault);
    return () => window.removeEventListener('load', activeDefault);
  }, []);

  const { menuData } = useSelector((state) => {
    return {
      menuData: state.menuProje.menuData,
    };
  }, shallowEqual);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'reduxMenuItemUpdate') {
        dispatch(MenuDataRead());
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch]);

  const addParentActive = (event) => {
    document.querySelectorAll('.parent').forEach((element) => {
      element.classList.remove('active');
    });

    localStorage.removeItem('filters');
    const hasSubMenuLeft = event.currentTarget.closest('.has-subMenu-left');
    const megaMenu = event.currentTarget.closest('.megaMenu-wrapper');
    if (!megaMenu) {
      event.currentTarget.closest('ul').previousSibling.classList.add('active');
      if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
    } else {
      event.currentTarget.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
    }
  };

  const [loading, setLoading] = useState(false);

  const fetchAndDownloadExcel = async () => {
    setLoading(true);
    try {
      // API çağrısı
      //   const [response1, response2, response3] = await Promise.all([
      //     DataService.post(`${process.env.REACT_APP_PERSONEL_PROJE_DURUMLARI_TUM_ARSIV_LIST_ENDPOINT}`, {
      //       orderColumnAndDirection: 2,
      //       limit: -2,
      //     }),
      //     DataService.post(`${process.env.REACT_APP_PERSONEL_PROJE_DURUMLARI_AKTIF_BAKANLIK_LIST_ENDPOINT}`, {
      //       orderColumnAndDirection: 2,
      //       limit: -2,
      //     }),
      //     DataService.post(`${process.env.REACT_APP_PERSONEL_PROJE_DURUMLARI_ARSIV_BAKANLIK_LIST_ENDPOINT}`, {
      //       orderColumnAndDirection: 2,
      //       limit: -2,
      //     }),
      //   ]);

      //   const tumArsiv = response1.data.model;
      //   const aktifBakanlikProje = response2.data.model;
      //   const arsivBakanlikProje = response3.data.model;

      //   const workbook = XLSX.utils.book_new();

      //   XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(tumArsiv), 'ArsivHepsi');
      //   XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(aktifBakanlikProje), 'AktifBakanlikProje');
      //   XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(arsivBakanlikProje), 'ArsivBakanlikProje');

      // const worksheet1 = XLSX.utils.json_to_sheet(tumArsiv);
      // worksheet1['!autofilter'] = { ref: 'A1:Z1' };

      // const worksheet2 = XLSX.utils.json_to_sheet(tumArsiv);

      // const worksheet3 = XLSX.utils.json_to_sheet(aktifBakanlikProje);
      // worksheet3['!autofilter'] = { ref: 'A1:Z1' };

      // const worksheet4 = XLSX.utils.json_to_sheet(aktifBakanlikProje);

      // const worksheet5 = XLSX.utils.json_to_sheet(arsivBakanlikProje);
      // worksheet5['!autofilter'] = { ref: 'A1:Z1' };

      // const worksheet6 = XLSX.utils.json_to_sheet(arsivBakanlikProje);

      // XLSX.utils.book_append_sheet(workbook, worksheet1, 'ArsivHepsi');
      // XLSX.utils.book_append_sheet(workbook, worksheet2, 'ArsivHepsiVeri');

      // XLSX.utils.book_append_sheet(workbook, worksheet3, 'AktifBakanlikProje');
      // XLSX.utils.book_append_sheet(workbook, worksheet4, 'AktifBakanlikProjeVeri');

      // XLSX.utils.book_append_sheet(workbook, worksheet5, 'ArsivBakanlikProje');
      // XLSX.utils.book_append_sheet(workbook, worksheet6, 'ArsivBakanlikProjeVeri');

      // const excelBuffer = XLSX.write(workbook, {
      //   bookType: 'xlsx',
      //   type: 'array',
      // });

      // const blob = new Blob([excelBuffer], {
      //   type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      // });
      // saveAs(blob, 'Proje-Durumlari.xlsx');

      const response = await DataService.getBlob(
        `${process.env.REACT_APP_PERSONEL_PROJE_DURUMLARI_EXCEL_EXPORT_ENDPOINT}`,
      );

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = downloadUrl;
      link.setAttribute('download', 'PersonelProjeDurumlari.xlsx');

      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(downloadUrl);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      NotificationHelper.setErrorNotification(t('processUnexpectedError'));
    }
  };

  return (
    <>
      {loading && (
        <SpinerCustomStyle>
          <Spin />
        </SpinerCustomStyle>
      )}
      <TopMenuStyle>
        <div className="ninjadash-top-menu">
          <ul>
            <Can
              perform={[
                MUHENDIS,
                FIRMA,
                SUBE_MUDURU,
                KAMU_MUHENDISI,
                KOMISYON_UYESI,
                KOMISYON_YEDEK_UYESI,
                UYGULAMA_YONETICISI,
                BAKANLIK_DAIRE_BASKANI,
                GENEL_MUDUR,
                GENEL_MUDUR_YARDIMCISI,
                IL_MUDURU,
                IL_MUDUR_YARDIMCISI,
              ]}
            >
              <li className="has-subMenu">
                <Link to="#" className="parent">
                  {t('menu.proje')}
                </Link>
                <ul className="subMenu">
                  <Can perform={[UYGULAMA_YONETICISI, FIRMA]}>
                    <li>
                      <NavLink onClick={addParentActive} to={`${path}/pages/wizards/proje`}>
                        <UilFile />
                        {t('menu.proje.yeniProje')}
                      </NavLink>
                    </li>
                  </Can>

                  <Can
                    perform={[
                      MUHENDIS,
                      FIRMA,
                      SUBE_MUDURU,
                      KAMU_MUHENDISI,
                      KOMISYON_UYESI,
                      KOMISYON_YEDEK_UYESI,
                      UYGULAMA_YONETICISI,
                      BAKANLIK_DAIRE_BASKANI,
                      GENEL_MUDUR,
                      GENEL_MUDUR_YARDIMCISI,
                      IL_MUDUR_YARDIMCISI,
                      IL_MUDURU,
                    ]}
                  >
                    <li>
                      <NavLink onClick={addParentActive} to={`${path}/proje/projelist/aktifProjelerim`}>
                        <UilBagAlt />
                        {t('menu.proje.aktifProjelerim')}
                        {menuData &&
                          (() => {
                            const projeler = menuData.find(
                              (x) => x.projeTipId === YerbisConstants.ProjeListesiTipleri.AktifProjelerim,
                            );
                            if (!projeler) {
                              return '';
                            }

                            const { okunanProjeSayisi, projeSayisi } = projeler;

                            return okunanProjeSayisi === -1
                              ? ` (${projeSayisi ?? 0})`
                              : ` (${okunanProjeSayisi ?? 0} / ${projeSayisi ?? 0})`;
                          })()}
                      </NavLink>
                    </li>
                  </Can>

                  <Can
                    perform={[
                      MUHENDIS,
                      SUBE_MUDURU,
                      KAMU_MUHENDISI,
                      KOMISYON_UYESI,
                      KOMISYON_YEDEK_UYESI,
                      UYGULAMA_YONETICISI,
                      BAKANLIK_DAIRE_BASKANI,
                      GENEL_MUDUR,
                      GENEL_MUDUR_YARDIMCISI,
                      IL_MUDUR_YARDIMCISI,
                      IL_MUDURU,
                    ]}
                  >
                    <li>
                      <NavLink onClick={addParentActive} to={`${path}/proje/projelist/imzaBekleyen`}>
                        <UilBagAlt />
                        {t('menu.proje.imzaBekleyen')}
                      </NavLink>
                    </li>
                  </Can>

                  <Can
                    perform={[
                      MUHENDIS,
                      FIRMA,
                      SUBE_MUDURU,
                      KAMU_MUHENDISI,
                      KOMISYON_UYESI,
                      KOMISYON_YEDEK_UYESI,
                      UYGULAMA_YONETICISI,
                      BAKANLIK_DAIRE_BASKANI,
                      GENEL_MUDUR,
                      GENEL_MUDUR_YARDIMCISI,
                      IL_MUDUR_YARDIMCISI,
                      IL_MUDURU,
                    ]}
                  >
                    <li>
                      <NavLink onClick={addParentActive} to={`${path}/proje/projelist/arsivProjelerim`}>
                        <UilBagAlt />
                        {t('menu.proje.arsivProjelerim')}
                      </NavLink>
                    </li>
                  </Can>

                  <Can
                    perform={[
                      SUBE_MUDURU,
                      KOMISYON_UYESI,
                      KOMISYON_YEDEK_UYESI,
                      UYGULAMA_YONETICISI,
                      BAKANLIK_DAIRE_BASKANI,
                    ]}
                  >
                    {!hasRole(SUBE_MUDURU) || user.organizationId === YerbisConstants.NodeIds.Bakanlik ? (
                      <li>
                        <NavLink onClick={addParentActive} to={`${path}/proje/projelist/komisyonProjeleri`}>
                          <UilBagAlt />
                          {t('menu.proje.komisyonProjeleri')}
                          {menuData &&
                            (() => {
                              const projeler = menuData.find(
                                (x) => x.projeTipId === YerbisConstants.ProjeListesiTipleri.KomisyonProjeleri,
                              );
                              return projeler
                                ? ` (${projeler.okunanProjeSayisi ?? 0} / ${projeler.projeSayisi ?? 0})`
                                : ' (0 / 0)';
                            })()}
                        </NavLink>
                      </li>
                    ) : (
                      ''
                    )}
                  </Can>

                  <Can
                    perform={[
                      SUBE_MUDURU,
                      KOMISYON_UYESI,
                      UYGULAMA_YONETICISI,
                      BAKANLIK_DAIRE_BASKANI,
                      GENEL_MUDUR,
                      GENEL_MUDUR_YARDIMCISI,
                      IL_MUDUR_YARDIMCISI,
                      IL_MUDURU,
                    ]}
                  >
                    <li>
                      <NavLink onClick={addParentActive} to={`${path}/proje/projelist/tumProjeler`}>
                        <UilBagAlt />
                        {t('menu.proje.tumProjeler')}
                      </NavLink>
                    </li>
                  </Can>

                  <Can
                    perform={[
                      SUBE_MUDURU,
                      KOMISYON_UYESI,
                      UYGULAMA_YONETICISI,
                      BAKANLIK_DAIRE_BASKANI,
                      GENEL_MUDUR,
                      GENEL_MUDUR_YARDIMCISI,
                    ]}
                  >
                    {!hasRoles([SUBE_MUDURU, KOMISYON_UYESI]) ||
                    user.organizationId === YerbisConstants.NodeIds.Bakanlik ? (
                      <li>
                        <NavLink onClick={addParentActive} to={`${path}/proje/projelist/ildenGelenProjeler`}>
                          <UilBagAlt />
                          {t('menu.proje.ildenGelenProjeler')}
                          {menuData &&
                            (() => {
                              const projeler = menuData.find(
                                (x) => x.projeTipId === YerbisConstants.ProjeListesiTipleri.IldenGelenProjeler,
                              );
                              return projeler
                                ? ` (${projeler.okunanProjeSayisi ?? 0} / ${projeler.projeSayisi ?? 0})`
                                : ' (0 / 0)';
                            })()}
                        </NavLink>
                      </li>
                    ) : (
                      ''
                    )}
                  </Can>

                  <Can
                    perform={[
                      SUBE_MUDURU,
                      KOMISYON_UYESI,
                      UYGULAMA_YONETICISI,
                      BAKANLIK_DAIRE_BASKANI,
                      GENEL_MUDUR,
                      GENEL_MUDUR_YARDIMCISI,
                      IL_MUDURU,
                      IL_MUDUR_YARDIMCISI,
                    ]}
                  >
                    {!hasRole([KOMISYON_UYESI]) && user.organizationId === YerbisConstants.NodeIds.Bakanlik ? (
                      <li>
                        <NavLink onClick={addParentActive} to={`${path}/proje/projelist/muhendisTalepleri`}>
                          <UilBagAlt />
                          {t('menu.proje.muhendisTalepleri')}
                          {menuData &&
                            (() => {
                              const projeler = menuData.find(
                                (x) => x.projeTipId === YerbisConstants.ProjeListesiTipleri.MuhendisTalepleri,
                              );
                              return projeler
                                ? ` (${projeler.okunanProjeSayisi ?? 0} / ${projeler.projeSayisi ?? 0})`
                                : ' (0 / 0)';
                            })()}
                        </NavLink>
                      </li>
                    ) : (
                      ''
                    )}
                  </Can>

                  <Can
                    perform={[
                      SUBE_MUDURU,
                      KAMU_MUHENDISI,
                      KOMISYON_UYESI,
                      KOMISYON_YEDEK_UYESI,
                      UYGULAMA_YONETICISI,
                      BAKANLIK_DAIRE_BASKANI,
                      GENEL_MUDUR,
                      GENEL_MUDUR_YARDIMCISI,
                      IL_MUDURU,
                      IL_MUDUR_YARDIMCISI,
                    ]}
                  >
                    <li>
                      <NavLink onClick={addParentActive} to={`${path}/proje/projelist/taslaklarim`}>
                        <UilBagAlt />
                        {t('menu.proje.taslaklarim')}
                      </NavLink>
                    </li>
                  </Can>

                  <Can
                    perform={[
                      SUBE_MUDURU,
                      KAMU_MUHENDISI,
                      KOMISYON_UYESI,
                      KOMISYON_YEDEK_UYESI,
                      UYGULAMA_YONETICISI,
                      BAKANLIK_DAIRE_BASKANI,
                      GENEL_MUDUR,
                      GENEL_MUDUR_YARDIMCISI,
                      IL_MUDUR_YARDIMCISI,
                      IL_MUDURU,
                    ]}
                  >
                    {' '}
                    <li className="has-subMenu-left">
                      <Link to="#" className="parent">
                        <UilBagAlt />
                        {t('menu.proje.bakanlikArsiv')}
                      </Link>
                      <ul className="subMenu">
                        <li>
                          <NavLink onClick={addParentActive} to={`${path}/proje/projelist/2018OncesiArsiv`}>
                            {t('menu.proje.2018OncesiArsiv')}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink onClick={addParentActive} to={`${path}/proje/projelist/2018SonrasiArsiv`}>
                            {t('menu.proje.2018SonrasiArsiv')}
                          </NavLink>
                        </li>
                      </ul>
                    </li>{' '}
                  </Can>

                  <Can perform={[SUBE_MUDURU, BAKANLIK_DAIRE_BASKANI, KAMU_MUHENDISI, UYGULAMA_YONETICISI]}>
                    <li>
                      <NavLink onClick={addParentActive} to={`${path}/tanimlar/formasyon/`}>
                        <UilLayerGroup />

                        {t('menu.tanimlar.formasyon')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={addParentActive} to={`${path}/tanimlar/litoloji/`}>
                        <UilMap />
                        {t('menu.tanimlar.litoloji')}
                      </NavLink>
                    </li>

                    <Can perform={[UYGULAMA_YONETICISI]}>
                      <li>
                        <NavLink onClick={addParentActive} to={`${path}/management/addupdate/cihazbilgileri`}>
                          <UilApps />
                          {t('menu.yonetim.cihaz')}
                        </NavLink>
                      </li>
                    </Can>
                  </Can>
                </ul>
              </li>
            </Can>

            {/* todo es burası düzenlenebilir */}
            <Can perform={[ADMIN, UYGULAMA_YONETICISI, SUBE_MUDURU, GENEL_MUDUR, GENEL_MUDUR_YARDIMCISI]}>
              <li className="has-subMenu">
                <Link to="#" className="parent">
                  {t('menu.yonetim')}
                </Link>
                <ul className="subMenu">
                  <Can perform={[SUBE_MUDURU, GENEL_MUDUR, GENEL_MUDUR_YARDIMCISI]}>
                    <li>
                      <NavLink onClick={addParentActive} to={`${path}/kullanici/kurumkullanicilari/`}>
                        <UilFile />
                        {t('menu.yonetim.tumKullanicilar')}
                      </NavLink>
                    </li>
                  </Can>

                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/kullanici/anasayfa/`}>
                      <UilFile />
                      {t('menu.yonetim.kullanici')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/kurum/anasayfa/`}>
                      <UilBagAlt />
                      {t('menu.yonetim.kurum')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/firma/anasayfa/`}>
                      <UilBagAlt />
                      {t('menu.yonetim.firma')}
                    </NavLink>
                  </li>
                  <Can perform={[UYGULAMA_YONETICISI]}>
                    <li>
                      <NavLink onClick={addParentActive} to={`${path}/muhendis/anasayfa/`}>
                        <UilBagAlt />
                        {t('menu.yonetim.muhendis')}
                      </NavLink>
                    </li>
                  </Can>
                </ul>
              </li>
            </Can>

            <Can perform={[FIRMA]}>
              <li className="megaMenu-wrapper">
                <NavLink className="parent" onClick={addParentActive} to={`${path}/muhendisislemleri/muhendisler/`}>
                  {t('menu.muhendisBilgileri')}
                </NavLink>
              </li>
            </Can>

            {/* <Can perform={[SUBE_MUDURU, BAKANLIK_DAIRE_BASKANI, KAMU_MUHENDISI, UYGULAMA_YONETICISI]}>
              <li className="has-subMenu">
                <Link to="#" className="parent">
                  {t('menu.tanimlar')}
                </Link>
                <ul className="subMenu">
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/tanimlar/formasyon/`}>
                      <UilLayerGroup />

                      {t('menu.tanimlar.formasyon')}
                    </NavLink>
                    <NavLink onClick={addParentActive} to={`${path}/tanimlar/litoloji/`}>
                      <UilMap />
                      {t('menu.tanimlar.litoloji')}
                    </NavLink>
                    <Can perform={[UYGULAMA_YONETICISI]}>
                      <NavLink onClick={addParentActive} to={`${path}/management/addupdate/cihazbilgileri`}>
                        <UilApps />
                        {t('menu.yonetim.cihaz')}
                      </NavLink>
                    </Can>
                  </li>
                </ul>
              </li>
            </Can> */}
            <Can perform={[BAKANLIK_DAIRE_BASKANI]}>
              <li className="has-subMenu">
                <Link to="#" className="parent">
                  {t('menu.gorevlendirme')}
                </Link>
                <ul className="subMenu">
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/gorevlendirme/muhendisatamalari`}>
                      {t('menu.gorevlendirme.muhendisAtamalari')}
                    </NavLink>
                    <NavLink onClick={addParentActive} to={`${path}/gorevlendirme/ilmuhendistalepleri/`}>
                      {t('menu.gorevlendirme.ilMuhendisTalepleri')}
                    </NavLink>
                  </li>
                </ul>
              </li>
            </Can>
            <Can perform={[ADMIN, BAKANLIK_DAIRE_BASKANI, GENEL_MUDUR, GENEL_MUDUR_YARDIMCISI, SUBE_MUDURU]}>
              {user.organizationId === YerbisConstants.NodeIds.Bakanlik && (
                <li>
                  <Link to="#" className="parent" onClick={fetchAndDownloadExcel}>
                    {t('personelProjeDurumu')}
                  </Link>
                </li>
              )}
            </Can>
            <Can perform={[ADMIN]}>
              {user.organizationId === YerbisConstants.NodeIds.Bakanlik && (
                <li className="megaMenu-wrapper">
                  <NavLink className="parent" onClick={addParentActive} to={`${path}/afetverianalizi/afetverianalizi`}>
                    {t('afetVeriAnaliz')}
                  </NavLink>
                </li>
              )}
            </Can>
            {/* <Can perform={[ADMIN]}>
              {user.organizationId === YerbisConstants.NodeIds.Bakanlik && (
                <li className="megaMenu-wrapper">
                  <NavLink className="parent" onClick={addParentActive} to={`${path}/logkayitlari/logkayitlari`}>
                    {t('logKayitlari')}
                  </NavLink>
                </li>
              )}
            </Can> */}
          </ul>
        </div>
      </TopMenuStyle>
    </>
  );
}

export default TopMenu;
